import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";
import { GatsbyImage } from "gatsby-plugin-image";
import DesktopLogo from "../images/Toot-Hill-Master-Logo-Reversed-new.svg";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

const Footer = () => {

  return (
    <footer className=" bg-primary">
      <Container className="py-5 py-lg-7">
        <Row>
          <Col className="text-center mb-4">
            <img
              src={DesktopLogo}
              alt="Toot Hill Golf Logo"
              style={{
                width: "300px",
              }}
            />
          </Col>
        </Row>
       
      </Container>
    </footer>
  );
};

export default Footer;
