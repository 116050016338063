import React from "react";
import { CookieBanner } from "@connorrjm/rjm-cookie-banner";
import Footer from "./googleAdsFooter";
import Navigation from "./googleAdsNavigation";
import { Helmet } from "react-helmet";

const Layout = ({ children, teeTimeLink }) => {
	return (
		<>
			<Navigation teeTimeLink={teeTimeLink}/>
			<CookieBanner disableUrl />
			{children}
			<Footer />
		</>
	);
};

export default Layout;
