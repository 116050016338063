import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import { Button, Container } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import MobileLogo from "../images/Toot-Hill-Master-Logo-Reversed-new.svg";

const Navigation = ({ teeTimeLink }) => {
  const data = useStaticQuery(graphql`
    query {
      wpBanner {
        bannerFields {
          bannerText
          showOnWebsite
        }
      }
    }
  `);

  return (
    <>
      <Navbar bg="primary" expand="xl">
        <Container
          style={{ zIndex: 2 }}
          className="position-relative text-end text-xl-start d-flex justify-content-between"
        >
          <Navbar.Brand as={Link} to="/">
            <img
              src={MobileLogo}
              alt="Toot Hill Golf Logo"
              style={{
                width: "120px",
              }}
            />
          </Navbar.Brand>
          <div className="menu-button">
            <Button
              variant="white"
              className="fs-6"
              href={
                teeTimeLink && teeTimeLink.url
                  ? teeTimeLink.url
                  : "https://bookings.toothillgolfclub.co.uk/"
              }
              target={teeTimeLink?.target ?? "_blank"}
              rel="noreferrer"
              style={{ padding: "10px 24px" }}
            >
              {teeTimeLink?.title ?? "Reserve tee time"}
            </Button>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigation;
